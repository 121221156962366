import { Ticket } from "@/generated";
import Button from "@mui/material/Button";
import { Link } from "gatsby";
import React from "react";
import TicketBox from "./ticketBox";

type TicketBoxLinkProps = {
  ticket: Ticket | null | undefined;
  loading: boolean;
};

const TicketBoxLink: React.FC<TicketBoxLinkProps> = ({ ticket, loading }) => (
  <TicketBox
    loading={loading}
    ticket={ticket}
    footer={
      <Button component={Link} to={`/berichten/${ticket.id}`}>
        Meer info
      </Button>
    }
  />
);

export default TicketBoxLink;
