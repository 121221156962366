import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const Wrapper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(5, 7, 4),
  backgroundColor: theme.palette.secondary.dark,
  borderBottomRightRadius: theme.spacing(12),

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(3, 4, 4),
  },
}));

export default Wrapper;
