import AddIcon from "@mui/icons-material/Add";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { SxProps, Theme } from "@mui/material/styles";
import React from "react";
import ContactForm from "./contactForm";
import AbsoluteCenter from "./generic/absoluteCenter";
import { useOpenClose } from "./hooks/useOpenClose";

type FloatingNewProps = {
  label?: string;
  top?: number;
  right?: number;
  sx?: SxProps<Theme>;
};

const FloatingNew: React.FC<FloatingNewProps> = ({ label, top, right, sx }) => {
  const [open, { handleOpen, handleClose }] = useOpenClose();

  return (
    <>
      <Box
        sx={[
          {
            position: "absolute",
            top: (theme) => theme.spacing(top ? top : 0),
            right: (theme) => theme.spacing(right ? right : 0),
            color: (theme) => theme.palette.primary.light,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Button variant="outlined" color="inherit" size="small" startIcon={<AddIcon />} onClick={handleOpen}>
          {label ? label : `Nieuw`}
        </Button>
      </Box>
      <Modal open={open} onClose={handleClose} onBackdropClick={handleClose} sx={{ outline: 0 }}>
        <AbsoluteCenter maxWidth="sm" sx={{ paddingLeft: "0!important", paddingRight: "0!important" }}>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <Button variant="text" color="tertiary" endIcon={<CloseRoundedIcon />} onClick={handleClose} tabIndex={-1}>
              Sluiten
            </Button>
          </Box>
          <ContactForm />
        </AbsoluteCenter>
      </Modal>
    </>
  );
};

export default FloatingNew;
