import Typography, { TypographyProps } from "@mui/material/Typography";
import React from "react";

type HeaderProps = Pick<TypographyProps, "variant">;

const Header: React.FC<HeaderProps> = ({ children, variant }) => (
  <Typography variant={variant ? variant : "h3"} component="p" color="primary" align="center" sx={{ mb: 6 }}>
    {children}
  </Typography>
);

export default Header;
