import Link, { LinkTypeMap } from "@mui/material/Link";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import React from "react";

type BaseLinkProps = Omit<DefaultComponentProps<LinkTypeMap<{}, "a">>, "color" | "underline" | "rel" | "sx">;

const BaseLink: React.FC<BaseLinkProps> = ({ children, ...linkProps }) => (
  <Link
    {...linkProps}
    color="tertiary.main"
    underline="hover"
    rel="noopener"
    sx={{
      transition: (theme) =>
        theme.transitions.create("color", {
          duration: theme.transitions.duration.shorter,
          easing: theme.transitions.easing.easeOut,
        }),
      "&:hover": { color: "primary.main" },
    }}
  >
    {children}
  </Link>
);

export default BaseLink;
