import { Ticket } from "@/generated";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React from "react";
import keystoneStatus from "../../../keystone/config/enums/status";
import DataBox from "./generic/dataBox";

type TicketBoxProps = {
  loading: boolean;
  ticket: Ticket | null | undefined;
  footer?: React.ReactNode;
};

const TicketBox: React.FC<TicketBoxProps> = ({ loading, ticket, footer }) => (
  <DataBox loading={loading} header="">
    <TextField
      label="Onderwerp"
      defaultValue={ticket?.subject}
      InputProps={{
        readOnly: true,
      }}
    />
    <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 1 }}>
      <TextField
        label="Datum"
        size="small"
        defaultValue={new Date(ticket?.createdAt).toLocaleString(undefined, {
          dateStyle: "medium",
          timeStyle: "short",
        })}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        label="Status"
        size="small"
        fullWidth={false}
        defaultValue={keystoneStatus.find((status) => status?.value === ticket?.status)?.label}
        InputProps={{
          readOnly: true,
        }}
      />
    </Box>

    <Box sx={{ minHeight: 4 }} />

    {/* Fall back to a space to force label of textfield in outline */}
    <TextField
      label="Bericht"
      defaultValue={ticket?.message || " "}
      multiline
      rows={8}
      InputProps={{
        readOnly: true,
      }}
    />

    {!!footer && footer}
  </DataBox>
);

export default TicketBox;
