import { GetLatestTicketDocument, GetTicketsDocument, useCreateTicketMutation } from "@/generated";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Link } from "gatsby";
import React, { FormEventHandler, useState } from "react";
import BaseLink from "./generic/baseLink";
import DataBox from "./generic/dataBox";
import { ButtonWrapper } from "./generic/form/buttonWrapper";

type ContactFormProps = {};

const Form = styled("form")(({ theme }) => ({
  width: "100%",
}));

const FormFields = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  padding: theme.spacing(0, 2),
  [theme.breakpoints.down("md")]: {
    padding: 0,
  },
}));

const ContactForm: React.FC<ContactFormProps> = () => {
  const [honeypot, setHoneypot] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [createTicket, { data, loading, error }] = useCreateTicketMutation({
    refetchQueries: [{ query: GetTicketsDocument }, { query: GetLatestTicketDocument }],
  });

  const reset = () => {
    setSubject("");
    setMessage("");
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (honeypot === "") {
      await createTicket({ variables: { data: { subject, message } } });
    }

    reset();
  };

  if (error) {
    return (
      <DataBox loading={loading} header="Helaas">
        <Typography>Er is iets mis gegaan.</Typography>
        <Typography>
          Probeer het anders eens via de telefoon op: <BaseLink href="+31619861019">06 - 19 86 10 19</BaseLink>
        </Typography>
      </DataBox>
    );
  }

  if (data?.createTicket?.id) {
    return (
      <DataBox loading={loading} header="Bedankt" maxWidth="md">
        <Typography align="center">
          Je bericht is succesvol verstuurd
          {data.createTicket.createdAt
            ? ` op ${new Date(data.createTicket.createdAt).toLocaleString("nl-NL", {
                dateStyle: "long",
                timeStyle: "medium",
              })}`
            : ``}
        </Typography>
        <Typography align="center">
          Je kunt de status van je melding volgen in je{" "}
          <MuiLink component={Link} to="/berichten/">
            berichten centrum
          </MuiLink>
          .
        </Typography>
        <Typography align="center" sx={{ marginBottom: 2 }}>
          Ik neem zo spoedig mogelijk contact met je op.
        </Typography>
      </DataBox>
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <DataBox loading={loading} header="Stuur een bericht">
        <FormFields>
          <TextField
            type="text"
            value={honeypot}
            onChange={(e) => setHoneypot(e.currentTarget.value)}
            disabled={loading}
            sx={{ display: "none" }}
          />
          <TextField
            type="text"
            value={subject}
            label="onderwerp"
            onChange={(e) => setSubject(e.currentTarget.value)}
            disabled={loading}
            tabIndex={1}
          />
          <TextField
            multiline
            rows={6}
            value={message}
            label="bericht"
            onChange={(e) => setMessage(e.currentTarget.value)}
            disabled={loading}
            tabIndex={2}
          />
          <ButtonWrapper>
            <Button variant="text" color="tertiary" disabled={loading} onClick={reset} tabIndex={4}>
              reset
            </Button>
            <Button variant="contained" type="submit" disabled={loading} tabIndex={3}>
              verstuur
            </Button>
          </ButtonWrapper>
        </FormFields>
      </DataBox>
    </Form>
  );
};

export default ContactForm;
