import { useState } from "react";

type UseOpenClose = (defaultValue?: boolean) => [
  boolean,
  {
    handleClose: () => void;
    handleOpen: () => void;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  },
];

export const useOpenClose: UseOpenClose = (defaultValue?: boolean) => {
  const [open, setOpen] = useState(defaultValue || false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return [open, { handleClose, handleOpen, setOpen }];
};
