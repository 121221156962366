import Container from "@mui/material/Container";
import { Breakpoint } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { ReactNode } from "react";
import CenteredLoading from "./centeredLoading";
import Wrapper from "./wrapper";

type DataBoxProps = {
  header: ReactNode;
  loading: boolean;
  maxWidth?: Breakpoint;
};

const DataBox: React.FC<DataBoxProps> = ({ header, loading, maxWidth, children }) => (
  <Container
    maxWidth={maxWidth ? maxWidth : "sm"}
    sx={{
      paddingX: {
        xs: 0,
        md: 4,
      },
    }}
  >
    <Wrapper>
      <Typography variant="h6" component="span" align="center" color="primary.main">
        {header}
      </Typography>
      {loading ? <CenteredLoading /> : <>{children}</>}
    </Wrapper>
  </Container>
);

export default DataBox;
