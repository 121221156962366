import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

type CenteredLoadingProps = {
  size?: number;
};

const CenteredLoading: React.FC<CenteredLoadingProps> = ({ size }) => (
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
    <CircularProgress size={size ? size : 58} />
  </Box>
);

export default CenteredLoading;
